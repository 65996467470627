<template>
  <div>
    <v-card>
      <v-card-title>APROBACIÓN SOLICITUDES</v-card-title>
      <v-card-text>
        <!-- <botonera 
                    :botones="botones" 
                    alinear="right" 
                    @crear="agregarSolicitud"
                >
                </botonera> -->
        <v-alert
          border="left"
          outlined
          text
          :type="alert.tipo"
          v-if="alert.mostrar"
        >
          <v-row>
            <v-col>
              <h4>{{ alert.mensaje }}</h4>
            </v-col>
            <v-col class="text-right">
              <v-btn
                :color="alert.color_boton"
                elevation="2"
                small
                dark
                @click="cerrar_alert"
                >Cerrar</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="alert.errores.length > 0">
            <v-col>
              <ul>
                <li v-for="e in alert.errores" :key="e">{{ e }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
    </v-card>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div :class="`card-icon ${color_icono}`">
          <i class="material-icons" v-show="icono">{{ icono }}</i>
        </div>
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-6">
              {{ titulo }}
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-2">
              <v-text-field
                placeholder="Número folio"
                v-model="folio_filtred"
                type="text"
                style="max-width:180px"
              />
            </div>
            <div class="col-md-1">
              <v-btn
                class="mx-1"
                fab
                dark
                small
                color="primary"
                @click="loadSolicitudMaterialesFiltred()"
              >
                <v-icon large style="color:white">
                  mdi-magnify
                </v-icon>
              </v-btn>
            </div>
          </div>
        </h4>
      </div>
      <div class="card-body">
        <spinner v-if="spinner"></spinner>
        <v-simple-table :height="table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">WELLBOAT</th>
                <th class="text-center">FOLIO</th>
                <th class="text-center">FECHA SOLICITUD</th>
                <th class="text-center" :style="{ 'min-width': sizeSelect2 }">
                  ENCARGADO AREA
                </th>
                <th class="text-center" :style="{ 'min-width': sizeSelect2 }">
                  ÁREA
                </th>
                <th class="text-center" :style="{ 'min-width': sizeSelect2 }">
                  CATEGORIA
                </th>
                <th class="text-center" :style="{ 'min-width': sizeSelect2 }">
                  ITEM
                </th>
                <th class="text-center">DESCRIPCIÓN</th>
                <th class="text-center">CANTIDAD SOLICITADA</th>
                <th class="text-center">ARCHIVO ADJUNTO</th>
                <th class="text-center">CANTIDAD APROBADA</th>
                <th class="text-center">CANTIDAD AUTORIZADA</th>
                <th class="text-center">ESTADOS</th>
                <th class="text-center acciones-column">ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in solicitudes"
                :key="index"
                class="text-center"
              >
                <td>
                  {{ item.wellboat }}
                </td>
                <td>
                  {{ `${item.centro_costo}-${item.folio}` }}
                </td>
                <td>
                  {{ item.fecha_solicitud }}
                </td>
                <td :style="{ 'max-width': sizeSelect2 }">
                  <!-- <v-select v-model="item.encargado_id" 
                                        :items="encargadosFilter(item)"
                                        item-text="name" item-value="id"
                                        label="Seleccione Encargado" color="blue darken-3" persistent-hint
                                        :reduce="item => item.id" style="max-width:150px"
                                        @change="updateEncatrgado(index)"
                                        disabled
                                    /> -->
                  {{ item.nombre_encargado }}
                </td>
                <td :style="{ 'max-width': sizeSelect2 }">
                  {{ item.area }}
                </td>
                <td :style="{ 'max-width': sizeSelect2 }">
                  {{ item.categoria }}
                </td>
                <td :style="{ 'max-width': sizeSelect2 }">
                  {{ item.material_codigo }}
                </td>
                <td>
                  {{ item.descripcion }}
                </td>
                <td>
                  {{ item.cantidad }}
                </td>
                <td>
                  <a
                    @click="downloadFile(item.archivo)"
                    target="_blank"
                    class="ma-2"
                  >
                    <v-icon
                      large
                      v-if="
                        item && item.archivo && item.archivo.includes('.pdf')
                      "
                      style="color:#ef233c"
                    >
                      mdi-file-pdf-box
                    </v-icon>
                    <v-icon
                      large
                      v-else-if="item.archivo != null"
                      style="color:#52b788"
                    >
                      mdi-file-image
                    </v-icon>
                  </a>
                </td>
                <td>
                  {{ item.revisor_cantidad_aprob }}
                </td>
                <td style="width=50px">
                  <v-text-field
                    placeholder="Cantidad Autorizada"
                    v-model="item.aprobador_cantidad_aprob"
                    type="number"
                    hide-details
                    :clearable="false"
                  />
                </td>
                <td>
                  {{ item.estado }}
                </td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        class="ma-1"
                        @click="duplicarSolicitud(index)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large color="warning">
                          mdi-plus-circle-multiple-outline
                        </v-icon>
                      </a>
                    </template>
                    <span>Duplicar Solicitud</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <a class="ma-1" @click="aprobarSolicitud(index, 4)" v-bind="attrs" v-on="on">
                                                <v-icon large color="success">
                                                    mdi-cart
                                                </v-icon>
                                            </a> -->
                      <a
                        class="ma-1"
                        @click="aprobar_rechazar(item, 4, index)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large color="success">
                          mdi-cart
                        </v-icon>
                      </a>
                    </template>
                    <span>Solicitud para Compra</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <a class="ma-1" @click="aprobarSolicitud(index, 5)" v-bind="attrs" v-on="on">
                                                <v-icon large color="blue">
                                                    mdi-store
                                                </v-icon>
                                            </a> -->
                      <a
                        class="ma-1"
                        @click="aprobar_rechazar(item, 5, index)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large color="blue">
                          mdi-store
                        </v-icon>
                      </a>
                    </template>
                    <span>Solicitud para retiro de Materiales</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <a class="ma-1" @click="rechazarSolicitud(index)" v-bind="attrs" v-on="on">
                                                <v-icon large color="red">
                                                    mdi-alpha-x-circle
                                                </v-icon>
                                            </a> -->
                      <a
                        class="ma-1"
                        @click="aprobar_rechazar(item, 6, index)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large color="red">
                          mdi-alpha-x-circle
                        </v-icon>
                      </a>
                    </template>
                    <span>Rechazar Solicitud</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <!-- <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
            ></v-progress-circular> -->
    </div>
    <v-alert
      v-if="emptyData && load_data"
      border="right"
      color="blue-grey"
      dark
    >
      No hay solicitudes de aprobación...
    </v-alert>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data: () => ({
    sizeSelect: "250px",
    sizeSelect2: "250px",
    spinner: false,
    table_height: 550,
    titulo: "CONSOLIDADO DE SOLICITUDES A APROBAR",
    color_icono: "azul",
    icono: "list",
    data_header: null,
    activePicker: null,
    // botones: [
    //     { boton: 'crear', tooltip: 'Agregar Fila' },
    //     { boton: 'cargar', tooltip: 'Carga Masiva' },
    //     {boton:'eliminar',tooltip:'Borrar Todo'}
    // ],
    solicitudes: [],
    // encargados: [],
    items: [],
    select_categoria: "",
    validation_message: "",
    color: "green",
    snackbar: false,
    load_data: false,
    data: [],
    alert: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    folio_filtred: "",
    disabled_button: false,
  }),
  mounted() {
    this.loadSolicitudes();
    // this.loadEncargados();
  },
  computed: {
    ...mapState([
      "loading",
      "base_url",
      "headers",
      "headers_file",
      "wellboat",
      "user",
      "storage_url",
    ]),
    emptyData: (data) => {
      return data.solicitudes.length > 0 ? false : true;
    },
  },
  methods: {
    // encargadosFilter(item){
    //     let result = this.encargados.filter(en => en.areas_ids.includes(item.areas_id))
    //     return result
    // },
    downloadFile(archivo) {
      const link = `${this.storage_url}solicitudes/${archivo}`;
      console.log(link);
      window.open(link);
    },
    cerrar_alert() {
      this.alert.mostrar = false;
      this.alert.tipo = "";
      this.alert.color_boton = "";
      this.alert.mensaje = "";
      this.alert.errores = [];
    },
    async loadSolicitudes() {
      this.spinner = true;
      this.mostrarLoading("Cargando listado de solicitudes...");
      let url = `${this.base_url}solicitudes/estado_solicitud/${2}`;
      let data = {
        roles: this.user.roles_ids,
        users_id: this.user.id,
      };
      await this.axios
        .post(url, data, this.headers)
        .then((response) => {
          this.load_data = true;
          this.solicitudes = response.data.data;
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
        });
      this.spinner = false;
    },
    async loadSolicitudMaterialesFiltred() {
      this.spinner = true;
      let url = `${this.base_url}solicitudes/dates`;
      let data = {
        folio: this.folio_filtred,
        roles: this.user.roles_ids,
        estado_solicitud: 2,
        areasId: this.user.areas_ids,
      };
      await this.axios
        .post(url, data, this.headers)
        .then((response) => {
          this.solicitudes = response.data.data;
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
          this.ocultarLoading();
        });
      this.spinner = false;
    },
    volver() {
      this.$router.push("solicitud_materiales");
    },
    showSnackBar(error) {
      if (error) {
        this.color = "red";
      } else {
        this.color = "green";
        this.validation_message = "Ejecutado exitosamente!";
      }
      this.snackbar = true;
    },
    duplicarSolicitud(index) {
      let nueva_solicitud = { ...{}, ...this.solicitudes[index] };
      nueva_solicitud.items_id = nueva_solicitud.id;
      nueva_solicitud.id = null;
      nueva_solicitud.aprobador_cantidad_aprob = null;
      console.log("nueva", JSON.stringify(nueva_solicitud));
      this.solicitudes.splice(index + 1, 0, nueva_solicitud);
      /* nueva_solicitud.id = null;
            nueva_solicitud.solicitudes_id = nueva_solicitud.folio;
            nueva_solicitud.file = '';
            this.solicitudes.push(nueva_solicitud); */
    },
    async aprobar_rechazar(item, estados_solicitudes_id, index) {
      if (!this.disabled_button) {
        this.disabled_button = true;
        this.cerrar_alert();
        let url = `${this.base_url}modulo_aprobacion/aprobar_rechazar`;
        item.estados_solicitudes_id = estados_solicitudes_id;
        item.aprobador_id = this.user.id;

        if (estados_solicitudes_id == 4 || estados_solicitudes_id == 5) {
          //4 = compra, 5 = retiro
          await this.axios
            .post(url, item, this.headers)
            .then((response) => {
              this.disabled_button = false;
              this.alert.mostrar = true;
              this.alert.tipo = "success";
              this.alert.mensaje = response.data.message;
              this.alert.color_boton = "green";
              //console.log(response)
              this.solicitudes.splice(index, 1);
            })
            .catch((e) => {
              this.disabled_button = false;
              this.alert.mostrar = true;
              this.alert.tipo = "error";
              this.alert.mensaje = e.response.data.message;
              this.alert.errores = e.response.data.errors;
              this.alert.color_boton = "red";
              this.$set(this.solicitudes[index], "id", e.response.data.item.id);
              //console.log(e.response.data)
            });
        }
        if (estados_solicitudes_id == 6) {
          //rechazar
          this.$confirm(
            "¿Estás seguro de que deseas rechazar esta solicitud?"
          ).then(async (res) => {
            console.log("res rechazo", res);
            if (res) {
              this.disabled_button = false;
              if (item.id != null) {
                await this.axios
                  .post(url, item, this.headers)
                  .then((response) => {
                    console.log(response);
                    this.solicitudes.splice(index, 1);
                  });
              } else {
                this.disabled_button = false;
                this.solicitudes.splice(index, 1);
              }
            }
          });
        }
      }
    },
    selectCategoria(value) {
      this.select_categoria = value;
    },
    selectItem() {
      this.select_categoria = "";
    },
    retornar_materiales(categorias_id) {
      if (categorias_id) {
        return this.items.filter((m) => m.categorias_id == categorias_id);
      }
      return [];
    },
    ...mapMutations(["mostrarLoading", "ocultarLoading"]),
  },
};
</script>
<style scoped>
.acciones-column {
  min-width: 260px;
}
th {
  background-color: #355f94 !important;
  color: #fff !important;
  border: 1px solid #fff;
}
</style>
